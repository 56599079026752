"use client";

import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { ptBR } from "date-fns/locale";
import { useContext, useEffect, useState } from "react";

import { InputAdornment, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { FaCircleMinus, FaCirclePlus } from "react-icons/fa6";
import ApiContext from "src/contexts/ApiContext";
import { useCountdownContext } from "src/contexts/CountdownContext";
import { getUpdatedTimestamp } from "src/utils/getUpdatedTimestamp";

const getFormattedTime = (
  date: Date,
  minutes: number,
  count: number
): string => {
  let displayMinutes = date.getMinutes() + minutes + 1;
  if (count <= 33) {
    displayMinutes += 1;
  }
  return `${String(date.getHours()).padStart(2, "0")}:${String(
    displayMinutes % 60
  ).padStart(2, "0")}`;
};

export default function TimePicker() {
  const { t } = useTranslation("dashboard");
  const { count } = useCountdownContext();
  const [minutes, setMinutes] = useState<number>(0);
  const [formattedTime, setFormattedTime] = useState<string>("");
  const { updateDatatime } = useContext(ApiContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const updateFormattedTime = () => {
    const now = new Date();
    const newFormattedTime = getFormattedTime(now, minutes, count);
    setFormattedTime(newFormattedTime);
    const updatedTimestamp = getUpdatedTimestamp(now, minutes + 1);
    updateDatatime(updatedTimestamp);
  };

  useEffect(() => {
    updateFormattedTime();
  }, [count, minutes]);

  const minAndMaxMinutes = (value: number): boolean => {
    return value >= 0 && value <= 59;
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value, 10);
    if (!isNaN(value) && minAndMaxMinutes(value)) {
      setMinutes(value);
    }
  };

  const increaseMinutes = () => {
    const newValue = minutes + 1;
    if (minAndMaxMinutes(newValue)) {
      setMinutes(newValue);
    }
  };

  const decreaseMinutes = () => {
    const newValue = minutes - 1;
    if (minAndMaxMinutes(newValue)) {
      setMinutes(newValue);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
      <TextField
        size={isMobile ? "small" : "medium"}
        sx={{
          "& .MuiInputBase-root input": {
            fontSize: isMobile ? "1rem" : "1.25rem",
            padding: isMobile ? "8.5px 0" : "0.850rem 0",
          },
        }}
        value={formattedTime}
        label={t("time")}
        onChange={handleChange}
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <FaCircleMinus size={24} onClick={() => decreaseMinutes()} />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment
              position="end"
              sx={{
                backgroundColor: "#15181A",
              }}
            >
              <FaCirclePlus size={24} onClick={() => increaseMinutes()} />
            </InputAdornment>
          ),
          inputProps: {
            readOnly: true,
          },
        }}
      />
      <Typography color="#919eab" variant="caption">
        {t("interval")}: 0 - 59 m
      </Typography>
    </LocalizationProvider>
  );
}
