import { Box, Skeleton, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/styles";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

import CountdonwContext from "src/contexts/CountdownContext";

function Countdown() {
  const { t } = useTranslation("dashboard");
  const { count, timeFormat } = useContext(CountdonwContext);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  if (timeFormat === "") {
    return (
      <Box pl={0.4} py={0.4}>
        <Skeleton variant="text" width={80} height={16} />
      </Box>
    );
  }

  return (
    <Box pl={0.4} py={0.4}>
      <Typography
        fontWeight={600}
        color={count < 6 ? "#FF5382" : "#00DB97"}
        variant="body2"
      >
        {t("time")}: {timeFormat}
      </Typography>
    </Box>
  );
}

export default Countdown;
