import PropTypes from "prop-types";
import {
  FC,
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { getBrokerNowTime } from "src/components/TVChartContainer/datafeed";
import { getLastBar } from "src/components/TVChartContainer/streaming";
import TradingViewContext from "./TradingViewContext";

interface CountdonwProviderProps {
  children: ReactNode;
}

interface CountdonwContextValue {
  timeFormat: string;
  count: number;
  selectedTimeFrame: any;
  setSelectedTimeFrame: (timeFrame: any) => void;
}

const CountdonwContext = createContext<CountdonwContextValue>({
  timeFormat: "",
  count: 0,
  selectedTimeFrame: null,
  setSelectedTimeFrame: () => null,
});


export const useCountdownContext = () => {
  const context = useContext(CountdonwContext);
  if (context === undefined) {
    throw new Error('useCountdownContext must be used within a CountdownProvider');
  }
  return context;
};


export const CountdonwProvider: FC<CountdonwProviderProps> = (props) => {
  const { children } = props;

  const { selectedSymbol } = useContext(TradingViewContext);

  const [selectedTimeFrame, setSelectedTimeFrame] = useState(null);
  const [count, setCount] = useState(0);
  const [timeFormat, setTimeFormat] = useState("");

  const lastBar = getLastBar(selectedSymbol, "CountdonwContext");

  function refreshClock() {
    if (!selectedTimeFrame) {
      return;
    }

    const displayFormatTime = new Date();
    const dataBroker = new Date(getBrokerNowTime());
    let resultTimeCalcululate = 0;
    let timer = 0;
    let minutes = dataBroker.getMinutes();
    let secounds = dataBroker.getSeconds();
    switch (selectedTimeFrame.value) {
      case "M5":
      case "M15":
        do {
          timer = timer + selectedTimeFrame?.valueChart;
        } while (!(minutes < timer));
        resultTimeCalcululate = (timer - minutes) * 60 - secounds;
        break;
      default:
        resultTimeCalcululate = selectedTimeFrame?.valueChart * 60 - secounds;
        break;
    }

    displayFormatTime.setHours(0);
    displayFormatTime.setMinutes(0);
    displayFormatTime.setSeconds(resultTimeCalcululate);
    displayFormatTime.setMilliseconds(0);
    setCount(resultTimeCalcululate);

    const time = `${resultTimeCalcululate < 600 ? "0" : ""}${Math.floor(
      resultTimeCalcululate / 60
    )}:${resultTimeCalcululate % 60 < 10 ? "0" : ""}${
      resultTimeCalcululate % 60
    }`;
    setTimeFormat(time);
  }

  useEffect(() => {
    const timerId = setInterval(refreshClock, 1000);
    return function cleanup() {
      clearInterval(timerId);
    };
  }, [lastBar?.time, selectedTimeFrame]);

  return (
    <CountdonwContext.Provider
      value={{
        count,
        timeFormat,
        selectedTimeFrame,
        setSelectedTimeFrame,
      }}
    >
      {children}
    </CountdonwContext.Provider>
  );
};

CountdonwProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CountdonwContext;
