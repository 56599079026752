import { Box } from "@mui/material";
import PlaceOrder from "./PlaceOrder";

export default function TradeOrder() {
  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <PlaceOrder />
    </Box>
  );
}
